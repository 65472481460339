@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer utilities {
  .gradient-horizontal {
    @apply bg-gradient-to-r from-[#eae3f6] via-[#ffffff8a] to-transparent;
  }

  .gradient-vertical {
    @apply bg-gradient-to-b from-[#eae3f6] via-[#ffffff8a] to-transparent;
  }
}

html {
  scroll-behavior: smooth;
  background: white;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.herogradient {
  background: linear-gradient(to right, #eae3f6, #fff, #ffffff8a, transparent);
}

@media screen and (max-width: 500) {
  .herogradientsm {
    background: linear-gradient(
      to bottom,
      #eae3f6,
      #fff,
      #ffffff8a,
      transparent
    );
    z-index: 10;
    display: none;
  }
}

.product-carousel {
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  color: #eae3f6;
}
.swiper-button-prev {
  margin-left: -35px;
}
.swiper-button-next {
  margin-right: -35px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
  display: none !important;
}

.swiper-pagination-bullet-active {
  background-color: #e03a3c !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  /* bottom: -5px !important; */
}

.noscrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}

.noscrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrow controls for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.customshadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.herobg {
  background-image: url("/public/assets/heromain.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  /* transform: scaleX(-1); */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; /* Change 'white' to the background color of your input */
  -webkit-text-fill-color: black; /* Adjust the text color if necessary */
}
.hidescroll::-webkit-scrollbar {
  width: 0;
}
.goog-te-gadget-icon {
  display: none;
}

.goog-te-gadget-simple {
  background-color: #ecebf0 !important;
  border: 0 !important;
  outline: 1px solid black;
  font-size: 10pt;
  font-weight: 800;
  display: inline-block;
  padding: 10px 10px !important;
  cursor: pointer;
  zoom: 1;
}

.goog-te-gadget-simple span {
  color: #000 !important;
}

body {
  top: 0px !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}
.VIpgJd-suEOdc {
  display: none !important;
}
